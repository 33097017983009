export const config = {
  "label": "prod",
  "ModuleFE_Thunderstorm": {
    "appName": "app-km-frontend - (prod)"
  },
  "ModuleFE_XHR": {
    "origin": "https://knowledge-manager-backend.quai.md",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyBKjXtVPLlUYAS2fcqYO8qJAWSiuWIr2fE",
      "authDomain": "quai-md-km.firebaseapp.com",
      "databaseURL": "https://quai-md-km-default-rtdb.firebaseio.com",
      "projectId": "quai-md-km",
      "storageBucket": "quai-md-km.appspot.com",
      "messagingSenderId": "84462366768",
      "appId": "1:84462366768:web:917baa50105f6873817df5",
      "measurementId": "G-QNZT7Y85BG"
    }
  }
};